import { mixinMutations } from '@/store/mixins';
const mutations = { ...mixinMutations() };
const actions = {
    async createRelease({ rootGetters }, payload) {
        const response = await this.postDataStore(`${rootGetters.API_URL}/updating-platform/releases`, payload, 'POST');
        return response;
    },
    async updateRelease({ rootGetters }, payload) {
        const { id, ...data } = payload;
        return await this.postDataStore(`${rootGetters.API_URL}/updating-platform/releases/${id}`, data, 'PUT');
    },
    async getReleases({ rootGetters }, payload) {
        return await this.getDataStore(`${rootGetters.API_URL}/updating-platform/releases`, payload);
    },
    async getRelease({ rootGetters }, { release_id }) {
        return await this.getDataStore(`${rootGetters.API_URL}/updating-platform/releases/${release_id}`, {});
    },
    async getUserViews({ rootGetters }, { release_id }) {
        return await this.getDataStore(`${rootGetters.API_URL}/updating-platform/releases/${release_id}/show-views`, {});
    },
    async viewedRelease({ rootGetters }, { release_id }) {
        return await this.postDataStore(`${rootGetters.API_URL}/updating-platform/releases/${release_id}/viewed`, {});
    },
    async toggleFavourite({ rootGetters }, { release_id, update_id }) {
        return await this.postDataStore(`${rootGetters.API_URL}/updating-platform/releases/${release_id}/toggle-favourite-update/${update_id}`, {});
    },
};
export default {
    namespaced: true,
    mutations,
    actions
};
